.parallax-section {
    height: 100vh;
    background-image: url('C:/Users/Sonoshee/Documents/Programming/MaxWebsite/max/img/headerimage.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.parallax-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.parallax-content {
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.15);
    padding: 10px 20px;
    border-radius: 10px;
    color: #813e00;
    text-align: center;
    box-shadow: 10px 6px 15px 10px rgba(0, 0, 0, 0.1);
}

.parallax-content h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3vw; /* Use viewport width units */
    margin-bottom: 10px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #B0846A;
    text-shadow: 2px 2px 4px #311f07;
    font-weight: 900; /* Makes the text bolder */
}

.parallax-content p {
    font-family: 'Lora', serif;
    font-size: 1.2vw; /* Use viewport width units */
    line-height: 1.4;
    color: #FFF;
    margin-top: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
    .parallax-content {
        padding: 8px 15px; /* Reduce padding */
    }

    .parallax-content h1 {
        font-size: 4vw; /* Smaller font size for header */
        margin-bottom: 8px;
    }

    .parallax-content p {
        font-size: 2.5vw; /* Smaller font size for paragraph */
        line-height: 1.2;
    }
}

@media (max-width: 480px) {
    .parallax-content h1 {
        font-size: 6vw; /* Further reduce font size on very small screens */
    }

    .parallax-content p {
        font-size: 3.5vw; /* Adjust paragraph size accordingly */
        line-height: 1.1;
    }
}
