.navbar {
  position: relative; /* Change from absolute to relative */
  width: 100%;
  background-color: #EDE0D4;
  color: #FFFFFF;
  padding: 10px 0px 25px 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 1000;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav-menu li {
  position: relative;
  width: 100%;
}

.nav-link {
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  background-color: #B0846A;
  text-align: left;
  font-size: 1.1em;
}

.nav-link:hover {
  background-color: #708090;
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  left: 50%; /* Start from the center */
  transform: translateX(-50%); /* Center horizontally */
  background-color: #708090; /* Darker background to stand out */
  border: 1px solid #9C8D78;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  min-width: 80%; /* Make it wider on mobile */
  max-width: 90%; /* Keep it within the screen bounds */
  z-index: 2000; /* Ensure it appears above other content */
  padding: 10px 0; /* Add some padding for spacing */
}

.dropdown-menu.open {
  display: flex; /* Show dropdown when open */
}

.dropdown-link {
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  padding: 10px;
  font-size: 1em;
  width: 200px;
  text-align: center; /* Center text within the dropdown */
}

.dropdown-link:hover {
  background-color: #9C8D78;
}

@media (min-width: 769px) {
  .nav-menu {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px 20px;
      justify-content: center; /* Centers the grid within the navbar */
      align-content: center; /* Vertically centers the grid items within the navbar */
      grid-auto-flow: dense; /* Fills in gaps to keep the grid visually balanced */
  }

  .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 7px ;
      background-color: #B0846A; /* Background color for the nav items */
  }

  .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      min-width: 200px;
      padding: 0;
  }
}


.nav-link.dropdown-toggle::after {
  content: '▼'; /* Add a downward arrow after the link text */
  font-size: 0.8em; /* Smaller font size for the arrow */
  margin-left: 5px; /* Space between text and arrow */
  color: #FFFFFF; /* Match the color of the text */
}

.nav-link.dropdown-toggle.open::after {
  content: '▲'; /* Change to an upward arrow when open */
}
