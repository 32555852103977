.footer {
  background-color: #EDE0D4;
  color: #B0846A;
  padding: 30px 20px;
  font-family: 'Georgia', serif;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  border-bottom: 1px solid #FFFFFF;
}

.footer-title {
  font-size: 2.5em; /* Larger font size */
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); /* Add subtle depth */
  margin-bottom: 20px;
}

.footer-info-centered {
  text-align: center;
  margin-bottom: 20px;
}

.footer-info-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.footer-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

.footer-text p {
  margin: 0;
  line-height: 1.5em;
  font-size: 1.2em;
  color: #B0846A; /* Brighter color for readability */
}

.footer-links {
  text-align: center;
  margin-top: 20px;
}

.footer-links ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-links ul li {
  display: inline-block;
  margin: 0 15px;
}

.footer-links ul li a {
  color: #B0846A;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #B0846A;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.9em;
  color: #DDD;
}
