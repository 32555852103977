.main-body {
    background-color: #F5F3EF; /* A light contrast color to the navbar and footer */
    color: #333333; /* Dark text color for good readability */
    padding: 60px 20px; /* Generous padding for a spacious feel */
    font-family: 'Georgia', serif; /* A serif font for a classy, traditional look */
    text-align: center;
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto; /* Center the content */
    padding: 20px;
    border: 1px solid #DDD; /* Subtle border to frame the content */
    background-color: #FFFFFF; /* Slightly off-white background for the content area */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for a touch of depth */
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    line-height: 1.4;
    color: #4A4A4A; /* Darker shade for the heading */
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555555; /* Slightly lighter than the heading for text contrast */
  }
  
  .signature {
    font-size: 1.5em;
    font-style: italic;
    margin-top: 40px;
    color: #4A4A4A; /* Darker shade for emphasis */
  } 
  
  .membership {
    font-size: 1em;
    margin-top: 10px;
    color: #888888; /* Even lighter shade for subtlety */
  }
  